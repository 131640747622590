export default `
  @media print {
    @page {
      size: A4 portrait;
      margin: 0;
    }

    div {
      display:none;
    }

    #noprint {
      display:block !important;
      margin-top: 10%;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
    }

  }


  @media screen {

    body {
      font-weight: normal;
    }

    #content {
      overflow-y: hidden;
    }

    #noprint {
      display:none !important;
    }

    .ui-selectmenu {
      top: 50px !important;
    }

    .print-documentpane {
      border: 0;
      margin: 0;
      padding: 0;
    }

    form.doc-page {
      margin-left: auto;
      margin-right: auto;
      border: 1px solid #DDD;
      background-color: white;
      position: relative;
      margin-top: 20px;
      overflow: hidden;
      color: #000;
    }

    form.print-page {
      overflow: hidden !important;
      border: 0;
      margin: 0;
      padding: 0;
      position: relative;
      text-overflow: clip;
      page-break-after: always;
    }

    form.landscape-print-page {
      margin-top: -3px !important;
    }

    .doc-numbering {
      font-size: 22px;
      line-height: 22px;
    }

    .doc-signature {
      background-color: rgba(244, 231, 55, 0.5);
      border: 1px solid #FFB238;
    }

    .doc-initial {
      background-color: rgba(244, 231, 55, 0.5);
      border: 1px solid #FFB238;
    }

    .doc-final .doc-signed {
      background-color: rgba(0,0,0,0);
    }

    .print-documentpane .doc-signature,
    .print-documentpane .doc-initial {
      background-color: rgba(0,0,0,0);
    }

    .doc-text {
      margin: 0;
      padding: 0;
      display: block;
      position: absolute;
      white-space: pre;
      cursor: default;
      text-shadow: none !important;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      -o-user-select: none;
      user-select: none;
    }

    .doc-rect {
      position: absolute;
      display: block;
      margin: 0;
      padding: 0;
      border-style: solid;
      border-width: 0;
    }

    .doc-line {
      margin: 0;
      padding: 0;
      position: absolute;
      display: block;
      border-style: none;
      border: 0;
    }
    
    .doc-photo {
      margin: 0;
      padding: 0;
      position: absolute;
      display: block;
      border: 4px solid #000;
    }

    .doc-photo-label {
      position: absolute;
      margin: 0;
      padding: 0;
      font-size: 36px;
    }

    .doc-photo-timestamp {
      margin: 0;
      padding: 0;
      position: absolute;
      color: yellow;
      font-size: 90%;
      z-index: 10000;
    }

    .doc-image {
      position: absolute;
      display: block;
    }

    .doc-textbox {
      color: #000;
      font-family: 'Liberation Sans';
      position: absolute;
      font-size: 32px;
      line-height: 36px;
      background: rgba(221, 228, 255, 0.5);
      border: none;
      margin: 0 -4px;
      padding: 0 4px;
      overflow: hidden;
      resize: none;
    }

    .doc-textarea {
      background-position: 0 -1px;
    }

    .doc-field-border-b {
      border-bottom: 1px solid #000;
    }

    .doc-field-border-lb {
      border-left: 1px solid #000;
      border-bottom: 1px solid #000;
    }

    .doc-field-border-all {
      border: 1px solid #000;
    }

    .doc-field-border-none {
      border: 0;
    }

    .doc-field-case-capitalize {
      text-transform: capitalize;
    }

    .doc-field-case-uppercase {
      text-transform: uppercase;
    }

    .doc-field-case-lowercase {
      text-transform: lowercase;
    }

    .doc-field-case-none {
      text-transform: none;
    }

    .hidefieldlines .doc-textbox,
    .hidefieldlines .doc-textarea {
      border: none;
    }

    .doc-textbox-medium {
      font-size: 22px;
      line-height: 26px;
    }

    .doc-textbox:focus {
      background-color: rgba(0,0,0,0);
      outline: none;
    }

    .doc-datefield {
      background-color: rgba(184, 247, 130, 0.4);
    }

    .doc-required {
      border:2px solid red !important;
    }

    .doc-checkbox {
      -webkit-tap-highlight-color: rgba(0,0,0,0);
      display: block;
      position: absolute;
      overflow: hidden;
      padding: 20px;
      margin: -20px;
    }

    .doc-checkbox:focus{
      outline: 0;
    }

    .doc-checkbox-wrapper {
      display: block;
      width: 100%;
      height: 100%;
      margin: -1px;
      background-color: rgba(221, 228, 255, 0.5);
      border: 1px solid #000;
      cursor: pointer;
    }

    .doc-checkbox-wrapper > input {
      -moz-appearance: none;
      -webkit-appearance: none;
      -webkit-box-sizing: content-box;
      -moz-box-sizing: content-box;
      box-sizing: content-box;
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    input[type=checkbox], .doc-checkbox-wrapper, .doc-layer-form, .doc-checkbox ::before, ::after {
      box-sizing: content-box !important;
    }

    .doc-checked .doc-checkbox-wrapper {
      background: url(/img/forms/check.png) no-repeat center;
    }

    .doc-checked .doc-strikebox-wrapper {
      background-color: #000;
    }

    .doc-strikebox {
      -webkit-tap-highlight-color: rgba(0,0,0,0);
      display: block;
      position: absolute;
      border: 0;
      padding: 20px;
      margin: -20px;
      cursor: pointer;
    }

    .doc-strikebox:focus{
      outline: 0;
    }

    .doc-strikebox-wrapper {
      margin-top: 5px;
      display: block;
      background-color: rgba(218, 1, 1, 0.2);
      width: 100%;
      height: 100%;
    }

    .doc-strikebox-child, .doc-strikebox-alt {
      display: none;
      cursor: default;
    }

    .doc-strikebox-child.doc-checked, .doc-strikebox-alt.doc-checked {
      display: block;
    }

    .doc-readonly {
      background-color: transparent;
      -webkit-text-fill-color:#000;
      color: #000;
    }

    .print-documentpane .doc-strikebox-wrapper, .print-documentpane .doc-checkbox-wrapper {
      background-color: transparent;
    }

    .print-documentpane .doc-checked .doc-strikebox-wrapper {
      background-color: #000;
    }

    .doc-strikebox-wrapper > input {
      display: none;
    }

    .doc-strikebox:checked {
      background-image: none;
      background-color: rgba(221, 228, 255, 0.5);
      border: 1px solid #000;
      border: none;
    }

    .doc-checkbox-print:checked {
      background-image: none;
      background-color: transparent;
      border: none;
    }

    .fulljustify {
      text-align: justify !important;
      white-space: normal !important;
      text-justify: distribute;
    }

    .fulljustify:after {
      content: "";
      display: inline-block !important;
      width: 100%;
    }

     .doc-combobox {
      color: #000;
      position: absolute;
      font-family: 'Arial';
      font-size: 30px;
      line-height: 34px;
      background-color: rgba(221, 228, 255, 0.5);
      border: none;
      padding: 1px;
      overflow: hidden;
      cursor: default;
    }

    .doc-combobox-dropdown {
      position: absolute;
      font-size: 32px;
      line-height: 36px;
      border: none;
      overflow: hidden;
      cursor: default;

      list-style: none outside;
      background-color: #fff;
      z-index: 10000;
      margin: 0;
      padding: 0;

      border-style: solid;
      border-color: #000;
      border-width: 0px 1px 1px 1px;

      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      -o-user-select: none;
      user-select: none;
    }

    .doc-combobox-dropdown li {
      margin: 0;
      padding: 2px 5px;
      font-family: Helvetica, Arial, sans-serif;
    }

    .doc-combobox-dropdown li.selected {
      background-color: #c6c7c8;
    }

    .doc-hide {
      display: none;
    }

    .doc-fixed-font {
      font-family: "Courier New", Courier, monospace;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      white-space: nowrap;
    }
  }
`;
